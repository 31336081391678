// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout-being-alive'
import {
  alive
} from '../components/layout.module.css'

// Step 2: Define your component
const AlivePage = () => {
  return (
	<Layout pageTitle="Alive!">
	  <form>
	  <p>Watch all the way until 6:30. With headphones, if you please.</p>
		<iframe width="500" height="281" src="https://www.youtube.com/embed/eBBPKedba5o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className={alive}></iframe>
	  </form>
	</Layout>
  )
}

// Step 3: Export your component
export default AlivePage